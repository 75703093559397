.glass__effect {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 1168px;
    height: 700px;
}

@media screen and (max-width: 480px) {
    .glass__effect {
        width: 100%;
        height: auto;
    }
}