.gallery {
    column-count: 3;
    column-gap: 12px;
    padding: 8px 12px;
    margin-top: 100px;
}

.gallery .pics {
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
    break-inside: avoid;
}

.gallery .pics:hover {
    filter: opacity(.8);
}

@media (max-width: 991px) {
    .gallery {
        column-count: 2;
        column-width: 100%;
    }
}

@media (max-width: 480px) {
    .gallery {
        column-count: 1;
        column-width: 100%;
    }
}

/* modal */

.modal {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    align-items: center;
    justify-content: center;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.modal.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.modal img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.modal.open svg {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    cursor: pointer;
}